import React from "react";
import { useTranslation } from "react-i18next";
import fileSE from "./Resources/Data/phone-nums-se.json";

const CallButtonModel = ({ product=1001, locale="se" }) => {
    const { t } = useTranslation();

    const getCountryFile = (locale) => {
        let item;
        switch(locale) {
            case "se":
            item =  fileSE.find((item) => item.product === product)
            return  item.number;
            case "no":
                return product === 1004
                ? "0770-117-000"
                : product === 1001
                ? "4721492400"
                :"010191840";
            case "fi":
                return product === 1004? "0770-117-000": "010191840";
            case "dk":
                return product === 1004? "0770-117-000": "010191840";
        }
    };

    return (

        <div className={`info-container-contact-info-call-button p-${product}`}>
          <a
            className={`info-container-contact-info-call-button-main-${product}`}
            href={`tel:${getCountryFile(locale)}`}>
            {product === 1004
                ? t("ContactText3")
                : product === 1003 || product === 1042
                ? t("ContactText2")
                : t("ContactText")}
          </a>
        </div>
    );
}

export default CallButtonModel;