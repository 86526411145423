import React from "react";
import logoData from "./Resources/Data/icon-json-test.json";

const LogoModel = ({ productId=0 }) => { 
    const item = logoData.find((item) => item.productID === productId);
    const string = item ? item.locale[0].svg : '';

    return (
      <div className={`header-logo-first-${productId}`}>
        {item ? (
          <div dangerouslySetInnerHTML={{ __html: string }} />
        ) : (
          <div/>
        )}
      </div>
    );
}

export default LogoModel;